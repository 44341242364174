<template>
   <section class="mb-10 solutions-holder">   
            <v-layout justify-space-around justify-center>
                <v-flex xs9 sm9 offset-sm1 class="">
                     <v-card flat tile color="" width="90%" class="transparent main-content-products text-center">
                        <v-card-title primary-title class="justify-center">
                            <h3 v-if="!is_screen_small" class="zamu-heading about-heading">Our Promise</h3>
                            <h3 v-if="is_screen_small" class="zamu-heading about-heading">Our <br /> Promise</h3>

                            <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center transact-underline mt-3">
                                <v-card-text class="transact-divider divider-purple"></v-card-text>
                                <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                            </v-card>
                        </v-card-title>
                        <v-card-title class="justify-center ">
                            <p class="mb-3 about-text">Our promise is <strong>Simple, Seamless, Secure and Fast transactions, always</strong>. Here is how we achieve it.</p>
                        </v-card-title>
                     </v-card>

                       <v-row no-gutters v-for="(section, index) in sectionInfo" :key="index" :class="{'reverse-div': section.reverse}" class="content-holder ">
                                    <v-col lg="6" md="6" sm="12" xs="12" class="pull-right  py-0" >
                                       <v-card color="transparent" flat tile height="100%" align="end" class="content-image-holder   py-0" >
                                           <v-card-text align="center" justify="center" class="py-0">
                                           <v-img :src="require(`../../../assets/images/${section.image}`)" class="content-image" :class="section.imgClass"></v-img>
                                           </v-card-text>

                                       </v-card>
                                    </v-col>
                                    <v-col lg="6" md="6" sm="12" xs="12" class="pull-left" >

                                         <v-row v-for="(content, i) in section.content" :key="i" class="mb-10">
                                            <v-col lg="10" md="10" sm="12" xs="12">
                                               <div class="content-title mb-3"> {{ content.header }} </div>
                                               <div class="content-text" v-html="content.text">
                                               </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                               
                            </v-row>
                </v-flex>
            </v-layout>
   </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name: 'Story',
    mixins: [colorMixin, coreMixin],

    computed : {
    
          sectionInfo () {
            const data = [
                {
                    section: 1,
                    reverse: true,
                    image: 'story.png',
                    imgClass: 'scaled-img',
                    content: [
                        {
                            number: '01',
                            header : 'Built-in toolkit for maximum transaction success',
                            text: `
                                <p>
                                 If you are transacting with the world, you need a global payment optimization engine that can withstand the complexities of cross-border payments. With connections to global banks and multiple mobile money providers, Zamupay’s All-in-One Payment Platform paves the most efficient path to payment success. 
                                </p>
                                <p>
                                In addition, to ensure your security and that of your customers, we work to ensure that we meet global standards of compliance and practice in payments and data management.
                                </p>
                            `
                        }
                    ]
                },
                {
                    section: 2,
                    reverse: false,
                    image: 'story.png',
                    imgClass: 'scaled-img',
                    content: [
                        {
                            number: '03',
                            header : 'Advanced Fraud Detection',
                            text: `
                           <p>
                            The Zamupay platform has a combination of automated fraud system monitoring tools that proactively flag high-risk activity, detects illegitimate transactional behavior online and provides alerts and analysis tools for personnel.
                            </p>
                            `
                        }
                    ]
                },
                {
                    section: 3,
                    reverse: true,
                    image: 'story.png',
                    imgClass: 'scaled-img',
                    content: [
                        {
                            number: '05',
                            header : 'Always On’ support service',
                            text: `
                             <p>
                            We have  unrivalled and unmatched customer support, with resolution speed within the shortest and minimal time possible. The customer support team is available and empowered around the clock to support you resolve issues as quickly as possible.
                            </p>
                            `
                        }
                    ]
                },
                {
                    section: 4,
                    reverse: false,
                    image: 'story.png',
                    imgClass: 'scaled-img',
                    content: [
                        {
                            number: '07',
                            header : 'Super API',
                            text: `
                            <p>
                            Zamupay APIs fosters the development of a vibrant ecosystem that allows customers to make online and offline payments, pay bills, keep track of their finances, and conduct a variety of other payment-related activities. Zamupay API will enhance your agility and ability to tap into a new and ready network of partners and developer innovators. 
                            </p>
                             `
                        }
                    ]
                }
            ];
            return data;
        },

    },


}
</script>

<style>
 .content-title {
     color: #43237d;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
 }
 .content-text {
    color: #727272;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
 }
 .content-image-holder {
    position: relative;

 }
.content-image {
    margin-bottom: 6em;

}
.content-holder {
    margin-bottom: 24px;
}
.scaled-img {
     width: 75%;
}
</style>